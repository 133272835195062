<template>
  <div class="Home">
    <!-- HERO -->
    <page-header/>

    <section>
      <div class="uk-container ">
        <div class="uk-text-center uk-margin">
          <ul class="uk-breadcrumb uk-flex-center uk-margin-remove">
            <li>
              <a href>{{$t("organization.profList.home")}}</a>
            </li>
            <li>
              <span>{{$t("organization.profList.pList")}}</span>
            </li>
          </ul>
          <!--<h1 class="uk-margin-small-top uk-margin-remove-bottom">Professor register</h1>-->
        </div>

        <div class="uk-width-1-1 uk-width-expand@m">

          <div class="uk-grid uk-grid-small uk-margin-medium-bottom uk-margin-medium-top" uk-margin>
            <div class="uk-inline">
              <input v-model="name" :placeholder="$t('organization.stuList.name')" class="uk-input ukInput" type="text">
            </div>
            <div class="uk-inline">
              <input v-model="email" :placeholder="$t('organization.stuList.email')" class="uk-input ukInput" type="email">
            </div>
            <div class="uk-inline">
              <date-picker
                class="date-picker"
                v-model="sdate"
                valueType="format"
                :lang="lang"
                :placeholder="$t('startDate')"
              ></date-picker>
            </div>
            <div class="uk-inline">
              <date-picker
                class="date-picker "
                v-model="edate"
                valueType="format"
                :lang="lang"
                :placeholder="$t('endDate')"
              ></date-picker>
            </div>
            <div>
              <button type="button" @click="reload()" class="uk-button
                            uk-button-default qbtn_d">{{ $t("orgaExam.examResultView.default") }}
              </button>
              <button type="button" @click="loaddata(1)" class="uk-button
                            uk-button-primary qbtn_d  uk-margin-small-left">{{ $t("organization.stuList.search") }}
              </button>
            </div>
          </div>
          <div class="uk-card uk-card-default uk-card-small tm-ignore-container">
            <header class="uk-card-header" style="border-top: 3px solid rgb(1, 176, 255) !important;">
              <div class="uk-grid-small uk-flex-middle" uk-grid>
                <div
                  class="uk-width-1-1 uk-width-expand@s uk-flex uk-flex-center uk-flex-left@s uk-text-small">
                                <span  style="margin-right:10px"
                                  class="uk-flex uk-flex-middle">{{$t("organization.profList.sort")}}:</span>
                  <div uk-form-custom="target: > * > span:first-child">
                    <select v-model="sortselected" @change="onChangeSort($event)" class="sortBy">
                      <option value="norm">{{$t("organization.profList.normal")}}</option>
                      <option value="name">{{$t("organization.profList.name")}}</option>
                      <option value="email">{{$t("organization.profList.email")}}</option>
                      <option value="enabled">{{$t("organization.profList.enabled")}}</option>
                    </select>
                    <button
                      class="uk-button uk-button-default btn-sortBy"

                      tabindex="-1">

                      <span uk-icon="icon: chevron-down"></span>
                    </button>
                  </div>
                </div>
<!--                <div class="uk-width-1-1 uk-width-auto@s uk-flex-middle">-->
<!--                  <button type="button" class="uk-button uk-button-primary" @click="excelModal()">Excel upload</button>-->
<!--                </div>-->
                <div class="uk-width-1-1 uk-width-auto@s uk-flex uk-flex-center uk-flex-middle" >
                  <router-link class="uk-button uk-button-default uk-button-small uk-hidden@m" style="color: #000000"
                        title="Professor register" tag="a" :to="{name: 'professorregister', params:{id:0}}"><span
                    class="uk-margin-xsmall-right"
                    uk-icon="icon: plus; ratio: .75;"
                  ></span>{{$t("organization.profList.cProf")}}
                  </router-link>
                  <div class="tm-change-view uk-margin-small-left">
                    <ul class="uk-subnav uk-iconnav js-change-view">
                      <li>
                        <router-link class="uk-button uk-button-default uk-button-small"
                                    style="color: rgb(1, 176, 255); border-color: rgb(1, 176, 255)"
                                     title="Professor register" tag="a"
                                     :to="{name: 'professorregister', params:{id:0}}"><span
                          class="uk-margin-xsmall-right"
                          uk-icon="icon: plus; ratio: .95;"
                        ></span>{{$t("organization.profList.cProf")}}
                        </router-link>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
            </header>
            <div class="uk-card-body">
              <table v-if="professors.length > 0" id="example"
                     class="uk-table uk-table-hover uk-table-striped uk-table-responsive"
                     style="width:100%">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>{{$t("image")}}</th>
                    <th>{{$t("organization.profList.fName")}}</th>
  <!--                  <th>{{$t("organization.profList.lName")}}</th>-->
                    <th>{{$t("ID")}}</th>
                    <th>{{$t("organization.profList.email")}}</th>
                    <th>{{$t("Role")}}</th>
                    <th>{{$t("organization.profList.enabled")}}</th>
                    <th>{{$t("organization.profList.created")}}</th>
                    <th>{{$t("organization.profList.actions")}}</th>
                  </tr>
                </thead>
                <tbody v-if="professors.length > 0">

                <tr v-for="(item,index) in professors"
                    :item="item"
                    :key="index">
                  <td>
                    <span v-if="currentPage == 0">
                      {{ index + 1 }}
                    </span>
                    <span v-else>
                      {{ ((currentPage * size) + index) - size + 1 }}
                    </span>
                  </td>
                  <td>
                    <div v-if="item.image != null && item.image != ''">
                      <img  :src="`/uploadingDir/${item.image}`" width="50"
                            :alt="item.image"/>
                    </div>
                    <img v-else width="45" src="../../assets/user_pro.png"/></td>
                  <td>{{item.firstName}}</td>
                  <td>{{item.username}}</td>
                  <td>{{item.email}}</td>
                  <td>
                    <span v-if="item.roles[0].name == 'ROLE_PROCTOR'" class="uk-text-success">
                      {{$t("Proctor")}}
                    </span>
                    <span v-else class="uk-text-primary">
                      {{$t("Professor")}}
                    </span>
                  </td>
                  <td>
                    <span v-if="item.enabled" class="uk-label uk-label-success">Success</span>
                    <span v-else class="uk-label uk-label-warning">Waiting</span>
                  </td>
                  <td>{{item.createdAt | moment}}</td>
                  <td>
                    <router-link title="Professor register" tag="a"
                                 :to="{name: 'professorregister', params: {id: item.id}}">
                      <span uk-icon="icon: file-edit; ratio: 1.5;"></span>
                    </router-link>
                    <a @click="showDelete(item)"
                       class="uk-text-danger uk-margin-small-left">
                      <span uk-icon="icon: trash; ratio: 1.5;"></span></a>
                  </td>
                </tr>

                </tbody>

              </table>
              <div v-else class="uk-alert-primary " uk-alert>

                <p class="uk-text-center">{{$t("organization.profList.notFound")}}</p>
              </div>
            </div>
            <div class="uk-card-footer uk-text-center">

              <div class="uk-flex uk-flex-center">

                <v-pagination v-model="currentPage"
                              :page-count="pages"
                              :classes="uikitClasses"
                              :labels="Labels"
                              @change="onChange"></v-pagination>
              </div>
            </div>
          </div>
        </div>
        <div id="showExcelModal" uk-modal>
          <div class="uk-modal-dialog">
            <button class="uk-modal-close-default"  uk-close></button>
            <div class="uk-modal-header">
              <h2 class="uk-modal-title uk-text-center"
                  style="font-size: 21px; font-weight: 700;">Excel upload</h2>
            </div>
            <div class="uk-modal-body uk-text-center">
              <div class="uk-upload-box">
                <div id="error-alert-file-upload" class="uk-alert-danger uk-margin-top uk-hidden" uk-alert>
                  <p id="error-messages-fileupload"></p>
                </div>
                <div class="js-upload uk-placeholder uk-text-center">
                  <span uk-icon="icon: cloud-upload"></span>
                  <span class="uk-text-middle uk-margin-small-left">Attach files by dropping them here or</span>
                  <div class="uk-margin">
                    <input type="file" name="document" class="uk-button uk-button-default">
                  </div>
                  <ul id="preview" class="uk-list uk-grid-match uk-child-width-1-2 uk-child-width-1-4@l uk-child-width-1-5@xl uk-text-center" uk-grid uk-scrollspy="cls: uk-animation-scale-up; target: .list-item; delay: 80"></ul>
                </div>
              </div>
              <p class="volume uk-margin-remove">Microsoft office 2008 - 2019</p>
              <p class="explain uk-margin-remove">{{$t("orgaQuestionList.mSize")}} : 10MB (XLSX)</p>
            </div>
            <div class="uk-modal-footer uk-text-right">
              <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close" >
                {{ $t("close") }}</button>
            </div>
          </div>
        </div>
      </div>

      <div id="deleteModal" uk-modal>
        <div class="uk-modal-dialog">
          <button class="uk-modal-close-default"  uk-close></button>
          <div class="uk-modal-header">
            <h2 class="uk-modal-title uk-text-center"
                style="font-size: 21px; font-weight: 700;">{{$t("dprof")}}</h2>
          </div>
          <div class="uk-modal-body uk-text-left">
            <h3>{{$t('userAskQuf')}}</h3>
            <h3>{{$t('username')}} : <span class="uk-text-primary uk-text-bold uk-margin-left"> {{username}}</span></h3>
          </div>
          <div class="uk-modal-footer uk-text-right">
            <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close">
              {{$t('no')}}
            </button>
            <button type="button" class="uk-button uk-button-primary uk-button-small uk-margin-left"
              @click="deleteUser()"> {{$t('yes')}}
            </button>
          </div>
        </div>
      </div>
    </section>

    <page-footer/>
  </div>

</template>


<script scoped>
  import UserService from '@/services/UserService'
  import moment from 'moment'
  import DatePicker from "vue2-datepicker";
  import 'vue2-datepicker/index.css';
  import vPagination from '@/components/globals/vue-plain-pagination'
  import {mapGetters} from 'vuex'
  import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'
  import UIkit from "uikit";
  const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")

  export default {
    name: 'Home',
    components: {
      vPagination,
      DatePicker,
      PageHeader,
      PageFooter,
    },
    data() {
      return {
        momentFormat: {
          stringify: (date) => {
            return date ? moment(date).format('LL') : ''
          },
          parse: (value) => {
            return value ? moment(value, 'LL').toDate() : null
          }
        },
        sdate: null,
        edate: null,
        name: null,
        email: null,
        error: null,
        size: 12,
        username: '',
        userDeleteId: 0,
        sortselected: 'norm',
        professors: [],
        currentPage: 1,
        pages: 0,
        typeTeacher: '',
        uikitClasses: {
          ul: 'uk-pagination',
          li: '',
          liActive: 'uk-active',
          liDisable: 'uk-disabled',
          button: 'page-link'
        },
        Labels: {
          first: 'First',
          prev: '<span uk-pagination-previous></span>',
          next: '<span uk-pagination-next></span>',
          last: "<span uk-icon='chevron-double-right'; ratio = '1.2'></span>"
        },
        lang: {
          days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          months: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ],
          placeholder: {
            dateRange: "Start date ~ End date"
          }
        },
        shortcuts: [
          {
            text: "Today",
            onClick: () => {
              this.time3 = [new Date(), new Date()];
            }
          }
        ],
        timePickerOptions: {
          start: "00:00",
          step: "00:30",
          end: "23:30"
        }

      }
    },

    filters: {
      moment: function (date) {
        return moment(date).format('YYYY.MM.DD')
        // return moment(date).format('YYYY-MM-DD, h:mm:ss a')
      }
    },
    created() {
    },
    async mounted() {
    },
    methods: {
      excelModal(){
        UIkit.modal('#showExcelModal').show()
      },
      reload(){
        window.location.reload()
      },
      // eslint-disable-next-line no-unused-vars
      onChangeSort(event) {
        this.loaddata(this.currentPage)
      },
      searchDate() {
        this.loaddata(this.currentPage)
      },
      showDelete(item){
        this.userDeleteId = item.id
        this.username = item.firstName
        UIkit.modal('#deleteModal').show();
      },
      async deleteUser() {
        try {
          const response = await UserService.userDelete({
            organizId: this.currentUser.organizs[0].organizId,
            userId: this.userDeleteId
          })

          if (response.data.status == 200) {
            alert(response.data.message)
            UIkit.modal('#deleteModal').hide();
            this.loaddata()
          }

        } catch (error) {
          console.log(error)
        }
      },
      async loaddata(page) {
        try {
          if (page > 0) {
            page = page - 1
          }
          const response = await UserService.getUsersByPage({
            name: this.name,
            email: this.email,
            sdate: this.sdate,
            edate: this.edate,
            page: page,
            role: 'professor',
            sort: this.sortselected,
            organizId: this.currentUser.organizs[0].organizId
          });

          if (response.status == 200) {
            this.professors = response.data.content;
            // this.currentPage = response.data.page;
            this.pages = response.data.totalPages;


            if (!this.professors.length) {
              this.isLoading = false
              return
            }
          }
        } catch (error) {
          // console.log(error)
          this.error = error.response.data.message;
          this.scrollToTop()
        }

      },
      scrollToTop() {
        window.scrollTo(0, 0)
      }
      ,
      onChange: function () {
        // console.log(`"currentPage" has been changed`)
        this.loaddata(this.currentPage)
      }

    },
    computed: {
      ...mapGetters(['isLoggedIn', 'currentUser'])
    }

  }
</script>

<style scoped>
  .sortBy{
    border: 1px solid #dee;
    border-radius: 15px;
    padding-left: 12px;
    outline: none;
    opacity: 0;
  }
  .btn-sortBy{
    border: 1px solid #dee;
    border-radius: 15px;
    outline: none;
    text-transform: capitalize;
  }

  .uk-pagination > li > a {
    font-size: 18px !important;
  }
  .qbtn_d {
    width: 120px;
    background-color: #01b0ff;
    border: 1px solid #fff;
    display: inline-block;
    padding: 0px 10px;
    color: #fff;
    font-weight: 500;
    border-bottom-left-radius: 1em;
    border-top-right-radius: 1em;
    border-top-left-radius: 1em;
    border-bottom-right-radius: 1em;
    min-width: 30px;
    text-align: center;
    text-transform: capitalize;
  }
  input{
    height: 40px;
  }
</style>
